import { EditorReadyFn } from 'yoshi-flow-editor-runtime';
import { i18n } from './config/i18n-editor';

const getTranslateFunction = async (locale: string, baseUrl: string) => {
  const { t } = await i18n(locale, baseUrl);
  return t;
};
const WIDGET_ID = 'd925427a-dc06-43f5-ad13-ae1f692f0feb';
let _token: string;
let _translate: Function;
let _isInFooter: boolean;

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  platformOptions,
  flowAPI,
) => {
  const { reportError } = flowAPI;
  const {
    firstInstall,
    initialAppData: { editorScriptUrl },
  } = platformOptions;
  _token = appDefinitionId;

  try {
    if (firstInstall) {
      moveToFooter(editorSDK);
      _isInFooter = true;
    } else {
      _isInFooter = await isInFooter(editorSDK);
    }

    editorSDK.document.application.registerToCustomEvents(appDefinitionId, {
      eventTypes: ['componentDragEnded'],
    });

    const baseUrl = editorScriptUrl.slice(
      0,
      editorScriptUrl.indexOf('/editorScript.'),
    );
    const locale = await editorSDK.environment.getLocale();
    _translate = await getTranslateFunction(locale, baseUrl);
  } catch (e) {
    reportError(e);
  }
};

export const onEvent: any = async (
  { eventType, eventPayload }: any,
  editorSDK: any,
) => {
  if (eventType === 'componentDragEnded' && eventPayload.compRef) {
    const data = await editorSDK.components.data.get(_token, {
      componentRef: eventPayload.compRef,
    });

    if (data && (data as any).widgetId === WIDGET_ID) {
      const wasInFooter = _isInFooter;
      _isInFooter = await isComponentInFooter(editorSDK, eventPayload.compRef);
      if (wasInFooter && !_isInFooter) {
        const action = await showMoveConfirmation(editorSDK);
        if (action === 'mainActionClicked') {
          moveToFooter(editorSDK);
        }
      }
    }
  }
};

const isInFooter = async (editorSDK: any): Promise<boolean> => {
  const widgetRef = await getWidgetComponentRef(editorSDK);
  return widgetRef ? isComponentInFooter(editorSDK, widgetRef) : false;
};

const getWidgetComponentRef = async (editorSDK: any) => {
  const allComponents = await editorSDK.document.components.getAllComponents(
    _token,
  );
  let widgetRef: any;

  for (const componentRef of allComponents) {
    const data = await editorSDK.components.data.get(_token, {
      componentRef,
    });
    if (data && (data as any).widgetId === WIDGET_ID) {
      widgetRef = componentRef;
      break; // circuit breaker since data.get is expensive.
    }
  }
  return widgetRef;
};

const isComponentInFooter = async (
  editorSDK: any,
  componentRef: any,
): Promise<boolean> => {
  const ancestors = await editorSDK.components.getAncestors(_token, {
    componentRef,
  });
  return ancestors.some((ancestor: any) => ancestor.id === 'SITE_FOOTER');
};

const moveToFooter = async (editorSDK: any) => {
  if (editorSDK.editor.components.moveToFooter) {
    const widgetRef = await getWidgetComponentRef(editorSDK);

    if (widgetRef) {
      editorSDK.editor.components.moveToFooter(_token, {
        componentRef: widgetRef,
      });
    }
  }
};

const showMoveConfirmation = async (editorSDK: any) =>
  editorSDK.editor.openConfirmationPanel(_token, {
    headerText: _translate('app.editor.move.title'),
    shouldShowIllustration: false,
    descriptionText: _translate('app.editor.move.text'),
    secondaryActionText: _translate('app.editor.move.close'),
    mainActionText: _translate('app.editor.move.backtofooter'),
  });
