import i18next, { Callback } from 'i18next';
import i18nextXHRBackend from 'i18next-xhr-backend';

const i18nextCore = (locale: string, baseUrl: string, cb: Callback) =>
  i18next.use(i18nextXHRBackend).init(
    {
      lng: locale,
      fallbackLng: 'en',
      keySeparator: '$',
      interpolation: {
        escapeValue: false,
      },
      backend: {
        loadPath: `${baseUrl}/assets/locales/messages_{{lng}}.json`,
        crossDomain: true,
      },
    },
    cb,
  );

export const i18n = (locale: string, baseUrl: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    const i18nInstance = i18nextCore(locale, baseUrl, (err, t) =>
      err ? reject(err) : resolve({ t, i18nInstance }),
    );
  });
};
